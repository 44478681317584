const Data =[
    {
        id:1,
        title:'Computerised Key',
        cover: require('../../Assets/service2.jpg'),
    },
    {
        id:2,
        title:'Car Remote Key',
        cover: require('../../Assets/service3.jpg')
    },
    {
        id:3,
        title:'Car Locks Repair',
        cover: require('../../Assets/service6.jpg')
    },
    {
        id:4,
        title:'Sensor Key',
        cover: require('../../Assets/service4.jpg')
    },
    {
        id:5,
        title:'Home Key',
        cover: require('../../Assets/service8.jpg')
    },
    {
        id:6,
        title:'Drawer Lock',
        cover: require('../../Assets/service1.jpg')
    },
    {
        id:7,
        title:'Flip key',
        cover: require('../../Assets/service5.jpg')
    },
    {
        id:8,
        title:'Chip Coding',
        cover: require('../../Assets/service7.jpg')
    },
]
export default Data;